/* eslint-disable global-require */
import React from 'react';
import { FeaturesSlider } from '@latitude/features-slider';

const RESPONSIVE_SETTINGS = [
  {
    breakpoint: 10000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    }
  }
];

function JustGotBetterSection({ anchor, featureData }) {
  return (
    <FeaturesSlider
      key={featureData.heading}
      className="lfs-just-got-better"
      heading={featureData.heading}
      subheading={featureData.description}
      id={anchor}
      data={featureData.featureCards}
      responsiveSettings={RESPONSIVE_SETTINGS}
    />
  );
}

export default JustGotBetterSection;
